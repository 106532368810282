import React from 'react';

const TeamMember = ({ image, handle, role, github }) => {
  return (
    <div className={'flex-col flex justify-center items-center m-4'}>
      <div className="team-member-image w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64">
        <img src={image} alt={handle} />
      </div>
      {github ? (
        <a href={github} target="_blank" className="team-member-handle">{handle}</a>
      ) : (
        <div className="team-member-handle">{handle}</div>
      )}
      <div className="team-member-role">{role}</div>
    </div>
  );
};

export default TeamMember;