import './App.css';
import TeamRoster from './components/TeamRoster';

const teamMembers = [
  { image: '/images/team_obhot_ascii_adj.png', handle: '@obhot', role: "growth", className: "team-obhot", github: "https://twitter.com/ollybirch"},
  { image: '/images/team_zer0cache_ascii_adj.png', handle: '@zerocache', role: "engineering", className: "team-zero", github: "https://github.com/zer0cache"},
  { image: '/images/team_bstar_ascii_adj.png', handle: '@b*', role: "engineering", className: "team-bstar"},
  { image: '/images/team_blockchainofregrets_ascii_adj.png', handle: '@blockchainofregrets', role: "engineering", className: "team-block", github: "https://github.com/blockchainofregrets"},
  { image: '/images/team_dungroller_ascii_adj.png', handle: '@dungroller', role: "engineering", className: "team-dung", github: "https://twitter.com/dungrollr" },
  { image: '/images/team_duelingbenjos_ascii_adj.png', handle: '@duelingbenjos', role: "engineering", className: "team-duel", github: "https://github.com/duelingbenjos"},
  { image: '/images/team_idiombytes_ascii_adj.png', handle: '@idiom_bytes', role: "engineering", className: "team-idiom", github: "https://github.com/idiom-bytes"},
];

function App() {
  return (
    <div className="App">
      <header className="App-header" class="p-4 bg-black text-white">
        <nav class="flex justify-center">
          <div class="flex space-x-4">
            <a href="https://warpcast.com/du3l73k" target="_blank" class="hover:underline">FARCASTER</a>
            <span class="text-white">|</span>
            <a href="https://twitter.com/DU3L73K" target="_blank" class="hover:underline">TWITTER</a>
            <span class="text-white">|</span>
            <a href="https://t.me/BE_Base" target="_blank" class="hover:underline">TELEGRAM</a>
            <span class="text-white">|</span>
            <a href="https://dexscreener.com/base/0xfea60036d200a28edc2c5061d958603141e1b62c" target="_blank" class="hover:underline">$BE</a>
          </div>
        </nav>
      </header>
      <TeamRoster teamMembers={teamMembers} />
    </div>
  );
}

export default App;
