import React from 'react';
import ReactDOM from 'react-dom';
import TeamMember from './TeamMember';

const TeamRoster = ({ teamMembers }) => {
  const rosterContent = (
    <div>
      <div className="flex items-center justify-center mx-auto mb-4">
        {teamMembers.slice(0, 1).map((member, index) => (
          <TeamMember key={index} image={member.image} handle={member.handle} role={member.role} github={member.github} />
        ))}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {teamMembers.slice(1,99).map((member, index) => (
          <TeamMember key={index} image={member.image} handle={member.handle} role={member.role} github={member.github} />
        ))}
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    rosterContent,
    document.getElementById('team-roster-container')
  );
};

export default TeamRoster;